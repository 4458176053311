import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DownloadLink from "@components/downloadLink"

function MealsKindergarden({ data }) {
  return (
    <Layout menuType="kindergarden">
      <SEO title="Dożywianie - ZSP Nidek" />
      <h1>Dożywianie</h1>
      <ul>
        <li>
          <DownloadLink
            data={data}
            strapiCollection="allStrapiInformacjaCzesne"
          >
            Informacja o zasadach naliczania i płatności za „ czesne”
          </DownloadLink>
        </li>

        <li>
          <DownloadLink
            data={data}
            strapiCollection="allStrapiZywieniePrzedszkole"
          ></DownloadLink>
        </li>
      </ul>
    </Layout>
  )
}

export default MealsKindergarden

export const query = graphql`
  query MealsKindergardenQuery {
    allStrapiInformacjaCzesne {
      edges {
        node {
          plik_info_czesne {
            id
            name
            url
            size
            ext
          }
        }
      }
    }
    allStrapiZywieniePrzedszkole {
      edges {
        node {
          plik_zywienie_przedszkole {
            id
            ext
            name
            size
            url
          }
        }
      }
    }
  }
`
