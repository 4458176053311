import React from "react"
import PropTypes from "prop-types"

function DownloadLink({ data, strapiCollection, children }) {
  const strapiName = Object.keys(data[strapiCollection].edges[0].node)[0]
  const file = data[strapiCollection].edges[0].node[strapiName]
  const fileURL = file.url.startsWith("http")
    ? file.url
    : `${process.env.STRAPI_BASE_URL}${file.url}`
  const fileSize = Math.round(file.size)
  const fileExtension = file.ext.slice(1)
  const fileName = file.name.replace(file.ext, "")

  return (
    <a key={file.id} href={fileURL} download>
      {`${children ? children : fileName} (${fileSize}KB | ${fileExtension})`}
    </a>
  )
}

DownloadLink.propTypes = {
  data: PropTypes.object.isRequired,
  strapiCollection: PropTypes.string.isRequired,
  children: PropTypes.string,
}

export default DownloadLink
